import { render, staticRenderFns } from "./ExpandableItemSalud.vue?vue&type=template&id=ac856174&scoped=true"
import script from "./ExpandableItemSalud.vue?vue&type=script&lang=js"
export * from "./ExpandableItemSalud.vue?vue&type=script&lang=js"
import style0 from "./ExpandableItemSalud.vue?vue&type=style&index=0&id=ac856174&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac856174",
  null
  
)

export default component.exports